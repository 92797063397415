import React, { useState } from "react";
import GridGallery from "react-grid-gallery";
import stencil from "images/stencil-2.png";
import separator from "images/separator.png";
import { useTranslations } from "gatsby-plugin-translate";

// Assets
import abraco1 from "images/gallery/2015/abraco1.jpg";
import abraco2 from "images/gallery/2015/abraco2.jpg";
import como_um_rio1 from "images/gallery/2015/como_um_rio1.jpg";
import como_um_rio2 from "images/gallery/2015/como_um_rio2.jpg";
import como_um_rio3 from "images/gallery/2015/como_um_rio3.jpg";
import danca1 from "images/gallery/2015/danca1.jpg";
import danca2 from "images/gallery/2015/danca2.jpg";
import mergulho1 from "images/gallery/2015/mergulho1.jpg";
import mergulho2 from "images/gallery/2015/mergulho2.jpg";
import mundo1 from "images/gallery/2015/mundo1.jpg";
import mundo2 from "images/gallery/2015/mundo2.jpg";
import mundo3 from "images/gallery/2015/mundo3.jpg";
import trapezio1 from "images/gallery/2015/trapezio1.jpg";
import trapezio2 from "images/gallery/2015/trapezio2.jpg";
import trapezio3 from "images/gallery/2015/trapezio3.jpg";
import nadador1 from "images/gallery/2015/nadador1.jpg";
import nadador2 from "images/gallery/2015/nadador2.jpg";
import poiso1 from "images/gallery/2015/poiso1.jpg";
import poiso2 from "images/gallery/2015/poiso2.jpg";
import image_224_hor_normal from "images/gallery/2017/224_hor_normal.jpg";
import image_229_hor_normal from "images/gallery/2017/229_hor_normal.jpg";
import image_279_hor_normal from "images/gallery/2017/279_hor_normal.jpg";
import image_287_hor_normal from "images/gallery/2017/287_hor_normal.jpg";
import image_094_vert_normal from "images/gallery/2017/094_vert_normal.jpg";
import image_109_vert_normal from "images/gallery/2017/109_vert_normal.jpg";
import image_111_hor_normal from "images/gallery/2017/111_hor_normal.jpg";
import image_203_vert_normal from "images/gallery/2017/203_vert_normal.jpg";
import image_212_vert_normal from "images/gallery/2017/212_vert_normal.jpg";
import image_312_vert_normal from "images/gallery/2017/312_vert_normal.jpg";
import image_303_hor_normal from "images/gallery/2017/303_hor_normal.jpg";
import image_216_hor_normal from "images/gallery/2017/216_hor_normal.jpg";
import image_447_vert_normal from "images/gallery/2017/447_vert_normal.jpg";
import image_115_hor_normal from "images/gallery/2017/115_hor_normal.jpg";
import image_118_hor_normal from "images/gallery/2017/118_hor_normal.jpg";
import image_123_hor_normal from "images/gallery/2017/123_hor_normal.jpg";
import image_137_hor_normal from "images/gallery/2017/137_hor_normal.jpg";
import image_158_hor_normal from "images/gallery/2017/158_hor_normal.jpg";
import image_173_hor_normal from "images/gallery/2017/173_hor_normal.jpg";
import image_315_hor_normal from "images/gallery/2017/315_hor_normal.jpg";
import image_325_hor_normal from "images/gallery/2017/325_hor_normal.jpg";
import image_175_hor_normal from "images/gallery/2017/175_hor_normal.jpg";
import image_190_hor_normal from "images/gallery/2017/190_hor_normal.jpg";
import image_191_hor_normal from "images/gallery/2017/191_hor_normal.jpg";
import image_368_hor_normal from "images/gallery/2017/368_hor_normal.jpg";
import image_389_hor_normal from "images/gallery/2017/389_hor_normal.jpg";
import image_429_hor_normal from "images/gallery/2017/429_hor_normal.jpg";
import fundicao1 from "images/gallery/2015/fundicao1.jpg";
import fundicao2 from "images/gallery/2015/fundicao2.jpg";
import fundicao6 from "images/gallery/2015/fundicao6.jpg";
import fundicao11 from "images/gallery/2015/fundicao11.jpg";
import fundicao3 from "images/gallery/2015/fundicao3.jpg";
import fundicao4 from "images/gallery/2015/fundicao4.jpg";
import fundicao7 from "images/gallery/2015/fundicao7.jpg";
import fundicao10 from "images/gallery/2015/fundicao10.jpg";
import fundicao5 from "images/gallery/2015/fundicao5.jpg";
import fundicao14 from "images/gallery/2015/fundicao14.jpg";
import fundicao16 from "images/gallery/2015/fundicao16.jpg";
import fundicao12 from "images/gallery/2015/fundicao12.jpg";
import fundicao13 from "images/gallery/2015/fundicao13.jpg";
import fundicao15 from "images/gallery/2015/fundicao15.jpg";
import fundicao9 from "images/gallery/2015/fundicao9.jpg";
import fundicao8 from "images/gallery/2015/fundicao8.jpg";
import fundicao18 from "images/gallery/2017/fundicao18.jpg";
import fundicao19 from "images/gallery/2017/fundicao19.jpg";
import fundicao20 from "images/gallery/2017/fundicao20.jpg";
import fundicao21 from "images/gallery/2017/fundicao21.jpg";
import fundicao22 from "images/gallery/2017/fundicao22.jpg";
import fundicao23 from "images/gallery/2017/fundicao23.jpg";
import fundicao24 from "images/gallery/2017/fundicao24.jpg";

// Thumbnails //
import abraco1_thumb from "images/gallery/2015/t/abraco1_thumb.jpg";
import abraco2_thumb from "images/gallery/2015/t/abraco2_thumb.jpg";
import como_um_rio1_thumb from "images/gallery/2015/t/como_um_rio1_thumb.jpg";
import como_um_rio2_thumb from "images/gallery/2015/t/como_um_rio2_thumb.jpg";
import como_um_rio3_thumb from "images/gallery/2015/t/como_um_rio3_thumb.jpg";
import danca1_thumb from "images/gallery/2015/t/danca1_thumb.jpg";
import danca2_thumb from "images/gallery/2015/t/danca2_thumb.jpg";
import mergulho1_thumb from "images/gallery/2015/t/mergulho1_thumb.jpg";
import mergulho2_thumb from "images/gallery/2015/t/mergulho2_thumb.jpg";
import mundo1_thumb from "images/gallery/2015/t/mundo1_thumb.jpg";
import mundo2_thumb from "images/gallery/2015/t/mundo2_thumb.jpg";
import mundo3_thumb from "images/gallery/2015/t/mundo3_thumb.jpg";
import trapezio1_thumb from "images/gallery/2015/t/trapezio1_thumb.jpg";
import trapezio2_thumb from "images/gallery/2015/t/trapezio2_thumb.jpg";
import trapezio3_thumb from "images/gallery/2015/t/trapezio3_thumb.jpg";
import nadador1_thumb from "images/gallery/2015/t/nadador1_thumb.jpg";
import nadador2_thumb from "images/gallery/2015/t/nadador2_thumb.jpg";
import poiso1_thumb from "images/gallery/2015/t/poiso1_thumb.jpg";
import poiso2_thumb from "images/gallery/2015/t/poiso2_thumb.jpg";
import fundicao1_thumb from "images/gallery/2015/t/fundicao1_thumb.jpg";
import fundicao2_thumb from "images/gallery/2015/t/fundicao2_thumb.jpg";
import fundicao6_thumb from "images/gallery/2015/t/fundicao6_thumb.jpg";
import fundicao11_thumb from "images/gallery/2015/t/fundicao11_thumb.jpg";
import fundicao3_thumb from "images/gallery/2015/t/fundicao3_thumb.jpg";
import fundicao4_thumb from "images/gallery/2015/t/fundicao4_thumb.jpg";
import fundicao7_thumb from "images/gallery/2015/t/fundicao7_thumb.jpg";
import fundicao10_thumb from "images/gallery/2015/t/fundicao10_thumb.jpg";
import fundicao5_thumb from "images/gallery/2015/t/fundicao5_thumb.jpg";
import fundicao14_thumb from "images/gallery/2015/t/fundicao14_thumb.jpg";
import fundicao16_thumb from "images/gallery/2015/t/fundicao16_thumb.jpg";
import fundicao12_thumb from "images/gallery/2015/t/fundicao12_thumb.jpg";
import fundicao13_thumb from "images/gallery/2015/t/fundicao13_thumb.jpg";
import fundicao15_thumb from "images/gallery/2015/t/fundicao15_thumb.jpg";
import fundicao9_thumb from "images/gallery/2015/t/fundicao9_thumb.jpg";
import fundicao8_thumb from "images/gallery/2015/t/fundicao8_thumb.jpg";

const Gallery = () => {
  const [selection, setSelection] = useState("all");
  const t = useTranslations();

  const sculptures = [
    {
      src: abraco1,
      thumbnail: abraco1_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 479,
      tags: [{ value: t`Col. Privada` }],
      caption: "Abraço (2014) - bronze / 36x13x25 cm - base bronze / 22x12 cm"
    },
    {
      src: abraco2,
      thumbnail: abraco2_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 479,
      tags: [{ value: t`Col. Privada` }], //--
      caption: "Abraço (2014) - bronze / 36x13x25 cm - base bronze / 22x12 cm"
    },
    {
      src: como_um_rio1,
      thumbnail: como_um_rio1_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }],
      caption: "Como um rio (2014) - bronze / 42x30x24 cm"
    },
    {
      src: como_um_rio2,
      thumbnail: como_um_rio2_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }], //--
      caption: "Como um rio (2014) - bronze / 42x30x24 cm"
    },
    {
      src: como_um_rio3,
      thumbnail: como_um_rio3_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }], //--
      caption: "Como um rio (2014) - bronze / 42x30x24 cm"
    },
    {
      src: danca1,
      thumbnail: danca1_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }],
      caption: "Dança (2014) - bronze / 47x37x13 cm - base bronze / 56x18 cm"
    },
    {
      src: danca2,
      thumbnail: danca2_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }], //--
      caption: "Dança (2014) - bronze / 47x37x13 cm - base bronze / 56x18 cm"
    },
    {
      src: mergulho1,
      thumbnail: mergulho1_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }],
      caption: "Mergulho (2014) - bronze / 41x24x10 cm"
    },
    {
      src: mergulho2,
      thumbnail: mergulho2_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }],
      caption: "Mergulho (2014) - bronze / 41x24x10 cm"
    },
    {
      src: mundo1,
      thumbnail: mundo1_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }],
      caption:
        "Mundo a seus pés (2015) - bronze / 47x35 cm - esfera (ferro oxidado) / 7 cm raio"
    },
    {
      src: mundo2,
      thumbnail: mundo2_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }], //--
      caption:
        "Mundo a seus pés (2015) - bronze / 47x35 cm - esfera (ferro oxidado) / 7 cm raio"
    },
    {
      src: mundo3,
      thumbnail: mundo3_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }], //--
      caption:
        "Mundo a seus pés (2015) - bronze / 47x35 cm - esfera (ferro oxidado) / 7 cm raio"
    },
    {
      src: trapezio1,
      thumbnail: trapezio1_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 479,
      tags: [{ value: t`Col. Privada` }],
      caption: "Trapézio (2014) - bronze / 44x21x10 cm"
    },
    {
      src: trapezio2,
      thumbnail: trapezio2_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 479,
      tags: [{ value: t`Col. Privada` }], //--
      caption: "Trapézio (2014) - bronze / 44x21x10 cm"
    },
    {
      src: trapezio3,
      thumbnail: trapezio3_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 479,
      tags: [{ value: t`Col. Privada` }], //--
      caption: "Trapézio (2014) - bronze / 44x21x10 cm"
    },
    {
      src: nadador1,
      thumbnail: nadador1_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }],
      caption: "Nadador (2014) - bronze / 43x10 cm - base granito / 10x12x9 cm"
    },
    {
      src: nadador2,
      thumbnail: nadador2_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }], //--
      caption: "Nadador (2014) - bronze / 43x10 cm - base granito / 10x12x9 cm"
    },
    {
      src: poiso1,
      thumbnail: poiso1_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }],
      caption: "Poiso de pássaro (2014) - bronze / 44x21x10 cm"
    },
    {
      src: poiso2,
      thumbnail: poiso2_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }], //--
      caption: "Poiso de pássaro (2014) - bronze / 44x21x10 cm"
    },
    {
      src: image_224_hor_normal,
      thumbnail: image_224_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Menina do rio (2015) - bronze / 15x18x21 cm"
    },
    {
      src: image_229_hor_normal,
      thumbnail: image_229_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Menina do rio (2015) - bronze / 15x18x21 cm"
    },
    {
      src: image_279_hor_normal,
      thumbnail: image_279_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Menina do rio (2015) - bronze / 15x18x21 cm"
    },
    {
      src: image_287_hor_normal,
      thumbnail: image_287_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Menina do rio (2015) - bronze / 15x18x21 cm"
    },
    {
      src: image_094_vert_normal,
      thumbnail: image_094_vert_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 479,
      caption: "Dar a volta ao medo (2016) - bronze / 66x34x28 cm - base bronze / 19x10 cm"
    },
    {
      src: image_109_vert_normal,
      thumbnail: image_109_vert_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 479,
      caption: "Dar a volta ao medo (2016) - bronze / 66x34x28 cm - base bronze / 19x10 cm"
    },
    {
      src: image_111_hor_normal,
      thumbnail: image_111_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Dar a volta ao medo (2016) - bronze / 66x34x28 cm - base bronze / 19x10 cm"
    },
    {
      src: image_203_vert_normal,
      thumbnail: image_203_vert_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 479,
      tags: [{ value: t`Col. Privada` }],
      caption: "Melancholia (2015) - bronze / 32x21 cm - base bronze / 11x13 cm"
    },
    {
      src: image_212_vert_normal,
      thumbnail: image_212_vert_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 479,
      tags: [{ value: t`Col. Privada` }], //--
      caption: "Melancholia (2015) - bronze / 32x21 cm - base bronze / 11x13 cm"
    },
    {
      src: image_312_vert_normal,
      thumbnail: image_312_vert_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 479,
      caption: "Um tempo suspenso (2015) - bronze / 47x27 cm - suporte aço / 10x10x12 cm"
    },
    {
      src: image_303_hor_normal,
      thumbnail: image_303_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Um tempo suspenso (2015) - bronze / 47x27 cm - suporte aço / 10x10x12 cm"
    },
    {
      src: image_216_hor_normal,
      thumbnail: image_216_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }],
      caption: "Melancholia (2015) - bronze / 32x21 cm - base bronze / 11x13 cm"
    },
    {
      src: image_447_vert_normal,
      thumbnail: image_447_vert_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 479,
      tags: [{ value: t`Col. Privada` }],
      caption: "A Estrada (2015) - bronze / 43x9 cm - suporte bronze / 51x56 cm"
    },
    {
      src: image_115_hor_normal,
      thumbnail: image_115_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }],
      caption: "De quase te tocar (2016) - bronze / 42x21x10 cm"
    },
    {
      src: image_118_hor_normal,
      thumbnail: image_118_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }], //--
      caption: "De quase te tocar (2016) - bronze / 42x21x10 cm"
    },
    {
      src: image_123_hor_normal,
      thumbnail: image_123_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }], //--
      caption: "De quase te tocar (2016) - bronze / 42x21x10 cm"
    },
    {
      src: image_137_hor_normal,
      thumbnail: image_137_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }], //--
      caption: "De quase te tocar (2016) - bronze / 42x21x10 cm"
    },
    {
      src: image_158_hor_normal,
      thumbnail: image_158_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }], //--
      caption: "De quase te tocar (2016) - bronze / 42x21x10 cm"
    },
    {
      src: image_173_hor_normal,
      thumbnail: image_173_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }], //--
      caption: "De quase te tocar (2016) - bronze / 42x21x10 cm"
    },
    {
      src: image_315_hor_normal,
      thumbnail: image_315_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }],
      caption: "A minha sombra é verde esperança (2015) - bronze / 53x20x11 cm - base bronze / 31x18 cm"
    },
    {
      src: image_325_hor_normal,
      thumbnail: image_325_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }], //--
      caption: "A minha sombra é verde esperança (2015) - bronze / 53x20x11 cm - base bronze / 31x18 cm"
    },
    {
      src: image_175_hor_normal,
      thumbnail: image_175_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      tags: [{ value: t`Col. Privada` }],
      caption: "De quase te tocar (2016) - bronze / 42x21x10 cm"
    },
    {
      src: image_190_hor_normal,
      thumbnail: image_190_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Como um lago da memória no teu corpo (2016) - bronze / 31x37x36 cm"
    },
    {
      src: image_191_hor_normal,
      thumbnail: image_191_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Como um lago da memória no teu corpo (2016) - bronze / 31x37x36 cm"
    },
    {
      src: image_368_hor_normal,
      thumbnail: image_368_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Como um lago da memória no teu corpo (2016) - bronze / 31x37x36 cm"
    },
    {
      src: image_389_hor_normal,
      thumbnail: image_389_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Como um lago da memória no teu corpo (2016) - bronze / 31x37x36 cm"
    },
    {
      src: image_429_hor_normal,
      thumbnail: image_429_hor_normal,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Como um lago da memória no teu corpo (2016) - bronze / 31x37x36 cm"
    }
  ];

  const foundry = [
    {
      src: fundicao1,
      thumbnail: fundicao1_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao2,
      thumbnail: fundicao2_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao1,
      thumbnail: fundicao1_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao2,
      thumbnail: fundicao2_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao6,
      thumbnail: fundicao6_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao11,
      thumbnail: fundicao11_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 479,
      caption: "Fundição"
    },
    {
      src: fundicao3,
      thumbnail: fundicao3_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao4,
      thumbnail: fundicao4_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao7,
      thumbnail: fundicao7_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao10,
      thumbnail: fundicao10_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao5,
      thumbnail: fundicao5_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao14,
      thumbnail: fundicao14_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao16,
      thumbnail: fundicao16_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao12,
      thumbnail: fundicao12_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao13,
      thumbnail: fundicao13_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao15,
      thumbnail: fundicao15_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao9,
      thumbnail: fundicao9_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao8,
      thumbnail: fundicao8_thumb,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao18,
      thumbnail: fundicao18,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao19,
      thumbnail: fundicao19,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao20,
      thumbnail: fundicao20,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao21,
      thumbnail: fundicao21,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao22,
      thumbnail: fundicao22,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao23,
      thumbnail: fundicao23,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    },
    {
      src: fundicao24,
      thumbnail: fundicao24,
      thumbnailWidth: 380,
      thumbnailHeight: 240,
      caption: "Fundição"
    }
  ];

  const images =
    selection === "all"
      ? [...sculptures, ...foundry]
      : selection === "sculptures"
        ? sculptures
        : foundry;

  return (
    <div className="gallery-container" id="galery">
      <img className="stencil" src={stencil} alt="stencil" />
      <img className="separator" src={separator} alt="separator" />
      <h2>{t`BRONZES BREVES`}</h2>
      <h3>{t`«O facto é que ninguém determinou, até agora, o que pode o corpo»`}</h3>
      <p>(ESPINOZA)</p>
      <div className="selectors">
        <button
          onClick={() => setSelection("foundry")}
          className={`button ${selection === "foundry" ? "selected" : ""}`}
        >
          {t`FUNDIÇÃO`}
        </button>
        <button
          onClick={() => setSelection("sculptures")}
          className={`button ${selection === "sculptures" ? "selected" : ""}`}
        >
          {t`ESCULTURAS`}
        </button>
      </div>
      <div className="gallery">
        <GridGallery
          tagStyle={{
            color: "rgba(0, 0, 0, 0.65)",
            display: "inline",
            padding: "0.2em 0.6em 0.3em",
            fontSize: "40%",
            fontWeight: "400",
            lineHeight: "1",
            background: "rgba(255, 255, 255, 0.65)",
            textAlign: "center",
            whiteSpace: "nowrap",
            fontFamily: "Montserrat, sans-serif",
            textTransform: "uppercase",
            letterSpacing: "2px",
            verticalAlign: "baseline",
            borderRadius: "0.25em"
          }}
          images={images} enableImageSelection={false} />
      </div>
    </div>
  );
};

export default Gallery;
