import React, { useEffect, useState } from "react";
import { useTranslateContext, useTranslations } from "gatsby-plugin-translate";

const Contact = ({ location }) => {
  const t = useTranslations();
  const { language } = useTranslateContext();
  const [message, setMessage] = useState('')

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const subject = params.get("subject");

    if (subject) {
      setMessage(language === "pt" ?
        `Gostaria de receber informações de preço acerca da peça "${subject}".` :
        `I would like to know pricing details regarding "${subject}" sculpture.`
      )
    }
  }, [language])

  return (
    <div className="contact-container">
      <div className="content">
        <h2>{t`ENTRE EM CONTACTO`}</h2>
        <form
          className="kwes-form contact-form"
          action="https://kwesforms.com/api/foreign/forms/8K9ycaPvCF0IofGnL3jL"
        >
          <input
            className="input"
            type="text"
            name="Nome"
            placeholder={t`NOME`}
            rules="required|max:255"
          />
          <input
            className="input"
            type="email"
            name="Email"
            placeholder={t`EMAIL`}
            rules="required|email|max:255"
          />
          <input
            className="input"
            type="tel"
            name="Telefone"
            placeholder={t`TELEFONE`}
            rules="required|max:255"
          />
          <textarea
            className="textarea"
            type="text"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            name="Mensagem"
            placeholder={t`MENSAGEM`}
            rules="required|max:255"
          />
          <button
            id="contact"
            className="button" type="submit">
            {t`ENVIAR`}
          </button>
        </form>
      </div>
      <div className="overlay" />
    </div>
  );
};

export default Contact;
